<template>
  <div class="mb-sm-5 mb-3">
    <h5 class="d-flex align-items-center mb-4">
      {{ title }}
      <span
        v-if="selectedFilters && selectedFilters.length"
        class="ml-2 selected-count w-color-primary"
      >
        ({{ selectedFilters.length }})
      </span>
    </h5>

    <Loadable :items="filters" />
    <div v-if="filters">
      <b-form-checkbox-group
        value="selectedFilters"
        @input="(newFilters) => filtersChanged(newFilters)"
        :options="
          showAll
            ? translatedFilters
            : translatedFilters.slice(0, showThreshold)
        "
        value-field="slug"
        text-field="name"
        stacked
      />

      <a
        v-if="filters.length > showThreshold"
        class="my-2 d-flex align-items-center font-weight-bold w-color-black"
        href="#"
        @click.prevent="toggleShowAllFilters(filters)"
      >
        {{ $t("Zobraziť") }} {{ showAll ? $t("menej") : $t("viac") }}
        <svg-caret-icon class="ml-2" :class="{ rotate180: showAll }" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "svg-caret-icon": () => import("/assets/icons/caret.svg?inline"),
    Loadable: () => import("/components/Loadable.vue"),
  },
  props: {
    title: { required: true, type: String },
    filters: { default: null, type: Array },
    showThreshold: { default: 5, type: Number },
    selectedFilters: { default: () => [], type: Array },
  },
  data() {
    return {
      // selectAll: false,
      showAll: false,
    };
  },

  computed: {
    translatedFilters() {
      return this.filters.map(({ disabled, id, name, slug }) => ({
        disabled,
        id,
        name: this.$t(name),
        slug,
      }));
    },
  },

  methods: {
    filtersChanged(newFilters) {
      this.$emit("update:selectedFilters", newFilters);
    },

    toggleShowAllFilters() {
      this.showAll = !this.showAll;
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-count {
  font-size: 0.875rem;
}

a {
  padding-left: 2rem;
  font-size: 1rem;
}

.custom-checkbox,
::v-deep .custom-checkbox {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

svg {
  transition: 0.3s ease;
}

.rotate180 {
  transform: rotate(180deg);
}
</style>
